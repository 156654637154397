<template>
  <footer
    class="bg-img-hero-footer bg-dark py-4"
    style="background-image: url(images/svg/components/bg-shape5e1f.svg?v=2)"
  >
    <div class="container">
      <div class="row justify-content-sm-between align-items-center">
        <div class="col-sm-6 mb-3 mb-sm-0">
          <div class="d-flex align-items-center">
            <!-- <img width="16" src="images/logo.png?v=0.0.2" alt="Rch Logo"> -->
            <span class="h5 text-white mb-0 ml-3">Powered by Rch Chain</span>
          </div>
        </div>
        <div class="col-sm-6 align-self-bottom"></div>
      </div>

      <hr class="opacity-md" />
      <div class="row justify-content-between align-items-center font-size-1">
        <div class="col-md-6 mb-2 mb-md-0 d-flex">
          <p class="mb-0 text-white">Rch © 2020</p>
        </div>
        <div class="col-md-6 text-md-right">
          <ul class="list-inline mb-0"></ul>
        </div>
      </div>
    </div>
  </footer>
</template>
